@import '../../assets/variables/variables.scss';

.expandService {
    background-color: $offwhite;    
    color: $dark_grey;         
    min-height: 100vh;
    
    @media (min-width:768px) {
        background-image: url('../../assets/images/portada.jpg');
        background-size: cover;
        padding: 2rem 0;                       
    }
}
.expandService-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    background-color: $offwhite;
    padding-top: 2rem;
    
    @media (min-width:768px){
        padding: 2.5rem;
    }
}
.expandService-container-animation {
    animation: bottomToTop 1s ease;
}
.expandservice-container-close {
    width: 100%;
    text-align: right;
    margin-bottom: 1rem;
    color: $dark_grey;
    &:hover {
        color: $orange;
    }
}
.expandService-img {
    width: 100%;
    height: 15rem;
    @media (min-width:768px){
        height: 20rem;        
    }
    
    @media (min-width:1280px){
        width: 32%;        
    }    
}
.carousel-inner {
    height: 100%;
}
.carousel-item{
    height: 100%;
}
.carousel-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.expandService-info {
    width: 100%;
    text-align: left;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    
    h1 {
        font-family: $oswald;
        font-size: 1.75rem;
        text-transform: uppercase;
        letter-spacing: 0.025rem;
        margin: 1.25rem 0;

    }    
    
    @media (min-width:1280px){
        width: 65%;
        padding-right: 3rem;
        h1 {
            margin: 0 0 1.25rem 0;
        }        
    }
}
.expandService-info-controls {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    a {
        color: $orange;
        font-size: 1.25rem;
        transition: all 0.25s ease;
        &:hover{
            color: $orange;
            font-size: 1rem;
        }
    }
    i {
        margin: 2rem 0.5rem 0 0.5rem;
    }
}