@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
    
}
@keyframes zoomIn {
    from {
        transform: scale(-1);
    }
    to {
        transform: scale(1);
    }
    
}
@keyframes bottomToTop {
    from {
        transform: translateY(100%);
        opacity: 0;
            
    }
    to {
        transform: translateY(0%);
        opacity: 1;
    }
       
}
@keyframes showService {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0%);
        opacity: 1;
    }    
}
@media (min-width: 768px){            
    @keyframes bottomToTop {
        from {
            transform: translateY(-100%);
            opacity: 0;
                
        }
        to {
            transform: translateY(0%);
            opacity: 1;
        }
           
    }
}   