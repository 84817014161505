@import '../../assets/variables/variables.scss';
@import '../../assets/animations/animations.scss';

.services {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
    position: relative;
    top: -7rem;
    @media (min-width: 1440px){
        top: -10rem;
    }
    
}
.services-list {
    width: 100%;    
    background-color: $offwhite;
    padding: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: $dark_grey;
    box-shadow: -4px 4px 10px 0px rgba(0,0,0,0.25);
    animation: showService 1.75s ease;
    h3 {
        text-transform: uppercase;
        font-family: $oswald;
        font-size: 1.25rem;
        letter-spacing: 0.025rem;
        margin-bottom: 1rem;
    }
    a {
        color: $dark_grey;
        font-weight: 600;
        font-size: .9rem;
        text-decoration: none;
        transition: all ease .25s;
        display: block;
        i{
            font-size: .75rem;
        }
        &:hover {
            color: $orange;
            transform: scale(1.05);
        }
    }   
    @media (min-width: 768px){
        width: 48%;        
    }
    @media(min-width:1440px){
    padding: 4rem;
    }
    
}
.services-list-icon {
    width: 15%;
    height: auto;
    margin-bottom: 0.75rem;
}