@import '../../assets/variables/variables.scss';

.novedades {
    padding: 5rem 0;
    color: $dark_grey;
    h1 {
        font-family: $oswald;
        font-weight: 400;
        font-size: 2rem;
        letter-spacing: 0.025rem;
        text-transform: uppercase;
        padding-bottom: 0.75rem;        
    }
    h4 {
        font-size: 1rem;
        text-transform: uppercase;
        padding-bottom: 3rem;
    }    
}
.novedades-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;   
}
.novedades-article {
    width: 100%;
    padding: 1.5rem;
    background-color: $offwhite;
    box-shadow: -4px 4px 10px 0px rgb(0 0 0 / 25%);
    text-align: left;    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-between;
    align-items: flex-end;
    justify-content: space-between;
    margin: .5rem 0;
    border-bottom: 4px solid $orange;    

    @media(min-width:768px){
        width: 48%;
        height: 650px;
    }
    @media(min-width:1280px){
        height: 625px;
    }
    @media(min-width:1440px){
        width: 32%;
    }
    a {
        color: #4D4D4D;
        font-weight: 600;
        font-size: 0.9rem;
        text-decoration: none;
        transition: all ease 0.25s;
        i{
            font-size: .75rem;
        }
        &:hover {
            color: $orange;
            transform: scale(1.05);
        }
    }

}
.novedades-body{
    h3 {
        font-family: $oswald;
        font-size: 1.2rem;
        padding: 1.5rem .25rem 1rem 0;
    }
}
.novedades-img {
    width: 100%;
    height: 250px;    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        padding: 0;
    }
}