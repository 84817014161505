@import '../../assets/variables/variables.scss';
@import '../../assets/animations/animations.scss';


.slider-img {          
    @media (max-width: 768px) and (orientation:portrait) {
        height: 75vh;
        }   
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            padding: 0;
            margin: 0;            
        }
}
.slider-intro {
    position: absolute;
    top: 25%;
    left: 0;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    animation: appear 2.5s ease;
    
       h1 {
        font-size: 1.5rem;        
        color: $offwhite;
        line-height: 1.75rem;
        margin-bottom: 2rem;
        width: 100%;
        font-family: $oswald;
        font-weight: 300;
        letter-spacing: .03rem;
        
        @media (min-width: 992px) {
            font-size: 1.9rem;
            line-height: 2.25rem;            
        }
        
    }
}
.img-filter {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.4;
}
.slider-intro-logo {
    width: 50%;
    margin-bottom: .75rem;
    /*animation: zoomIn 3s ease;*/
    @media(min-width:992px){
        width: 25%;
    }
}
.slider-intro-btn {
    background-color: $orange;
    padding: .5rem 1rem;
    color:$offwhite;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    transition: all ease .25s;
    &:hover{
        color: $offwhite;
        background-color: transparent;
        border: 3px solid $orange;
    }
    @media(min-width:992px){
        padding: 1rem 2rem;
    }
}