@import '../../assets/variables/variables.scss';

.header-navbar {
    font-family: 'Oswald', sans-serif;    
    text-transform: uppercase;
    letter-spacing: .025rem;
    background-color: $dark_grey !important;
    a {
        color: rgba($color: $white, $alpha: 0.75) !important;
        &:hover{
            color: rgba($color: $white, $alpha: 1.0) !important;
        }
    }
    @media (min-width: 992px){
        background-color:rgba(0,0,0,.1) !important;
    }
}

.navbar-active {
    font-family: 'Oswald', sans-serif;   
    text-transform: uppercase;
    letter-spacing: .025rem;
    background-color: $dark_grey !important;
    border-bottom: 2px solid $orange;
    a {
        color: rgba($color: $white, $alpha: 0.75) !important;
        &:hover{
            color: rgba($color: $white, $alpha: 1.0) !important;
        }
    }
}
.navbar-nav {
    @media (min-width:992px) {
        margin-right: 4rem !important;        
    }
}
.nav-link{
    padding-left: 0.75rem !important;
    padding-right: .75rem !important;
}
.dropdown-menu.show{
    background-color: $dark_grey;
    text-align: center;
    @media (min-width:992px){
        text-align: left;
    }
}
.dropdown-item{
    font-size: .8rem !important;
    
    &:hover{
        background-color: $orange !important;
    }
}
.icon-rss-ig {    
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    background-size: 100%;
    border-radius: 100%;   
    height: 1.5rem;
    width: 1.5rem;
    font-size: .95rem;
}
.icon-rss-linkedin {
    background-color: #0e76a8;
    background-size: 100%;
    border-radius: 100%;   
    height: 1.5rem;
    width: 1.5rem;
    font-size: .95rem;
}