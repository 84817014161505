@import '../../assets/variables/variables.scss';

.copyright {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: #fff;
    justify-content: center;
    align-items: center;
    @media(min-width:768px){
        justify-content: space-between;
    }   
    
    ul {
        order: 1;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        padding: 0;
        margin-bottom: 0;

        @media(min-width:768px){
            width: auto;
            order: 3;
        }                
        li {
            
            padding: .25rem;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: .5rem;
            &:first-of-type {
                background: #d6249f;
                background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
            }
            &:last-of-type {
                background-color: #0e76a8;
            }
            a {
                color: white;
            }
        }
    }
}
.copyrigth-info{
    order:3;
    text-align: center;
    color: $offwhite;
    width: 100%;
    p {
        margin-bottom: 0;
        font-size: .75rem;
        a {
            color: lightgray;
            text-decoration: none;
        }
    }
    @media(min-width:768px){
        text-align: left;
        order: 1;
        width: auto;
    } 
    
}
.copyright-logo {
    width: 100px;
    height: auto;
    order: 2;
    margin: 1rem;
    img {
        width: 100%;
        height: auto;
    }
    @media(min-width:768px) {
        margin: 0;
    }
}
