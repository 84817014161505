@import '../../assets/variables/variables.scss';

.contact {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    color: $offwhite;
    padding: 3rem 0;
}
.contact-info {
    width: 100%;
    order: 2;
    margin: 0 auto;
    ul {
        padding: 0;
        li {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            text-align: left;

            span {
                width: auto;
                height: auto;
                padding: 1rem 1.25rem;
                background-color: $orange;
                border-radius: 100%;
            }
            div {
                margin-left: 2rem;
                h4 {
                    font-size: 1.1rem;
                    font-family: $oswald;
                    font-weight: 400;
                    letter-spacing: .025rem;
                }

            }
        }
    }
    @media (min-width:768px) {
        width: 48%;
        order: 1;        
    }
}
.contact-info-map {
    width: 96%;
    height: 300px;
    iframe {
        width: 100%;
        height: 100%;
    }
}
.contact-form {
    position: relative;
    color: $offwhite;
    margin: 0 auto 3.5rem auto;
    order: 1;
    width: 100%;
    h1 {
        text-align: left;
        font-family: $oswald;
        font-weight: 400;
    }
    h4 {
        text-align: left;
        font-size: 1rem;
        margin-bottom: 2rem;
    }
   
    @media (min-width:768px) {
        width: 45%;
        order: 2;        
    }
    input, textarea{
        border: none;
        border-bottom:  2px solid $orange;
        &:focus {
            border: 2px solid $orange;
            box-shadow: inset 0 1px 1px rgba(161, 160, 160, 0.075), 0 0 8px rgba(158, 156, 156, 0.6);
            
        }
    }
}
.contact-form-btn {
    background-color: $orange;
    border: none;
    margin-top: 3rem;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    color: $offwhite;
    transition: all ease 0.25s;
    &:hover{
        border: 2px solid $orange;
        background-color: transparent;
    }
    @media (min-width:992px){
        padding: 1rem 2rem;
    }
}
.contact-form-success{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $dark_grey;
    z-index: 1000;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    h4 {
        text-align: center;
    }
}