* {
  margin: 0;
  padding:0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-weight: normal;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Montserrat', sans-serif;
}
.content-margin {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
@media (min-width:1024px) {
  .content-margin{
  margin-left: 6rem;
  margin-right: 6rem;
  }    
}